<template>
  <footer
    id="footer"
    class="relative text-base leading-snug text-white bg-neutral-900"
  >
    <!-- <ContactHoverButton /> -->
    <div
      class="relative flex flex-col w-auto h-full px-0 pt-12 pb-8 mx-4 my-0 xl:max-w-6xl xl:mx-auto"
    >
      <div class="w-full mb-8">
        <router-link to="/">
          <img src="~@/assets/RMRGreen_Logo_White.png" class="mx-auto h-16" />
        </router-link>
      </div>
      <div
        class="flex flex-col flex-wrap justify-around align-top md:flex-row md:justify-between"
      >
        <div
          class="flex flex-col justify-around flex-grow sm:flex-row lg:justify-between lg:mr-40"
        >
          <div class="mb-8 text-center sm:text-left sm:ml-4 sm:w-36">
            <h3 class="mt-0 font-semibold">PARTICIPANTS</h3>
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/login"
              >Participant Login</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/services/cobra"
              >COBRA</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/forms"
              >Forms</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/whats-covered"
              >What's Covered</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/support"
              >Support & FAQ</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/services"
              >Our Services</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/calculators"
              >Calculators</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/how-to-videos"
              >How to Videos</router-link
            >
          </div>
          <div class="mb-8 text-center sm:text-left sm:ml-4 sm:w-40">
            <h3 class="mt-0 font-semibold">EMPLOYERS / BROKERS</h3>
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/login/employer"
              >Employer Login</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/employer-resources"
              >Employer Resources</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/services"
              >Our Services</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/employer-training"
              >Employer Training</router-link
            >
            <router-link
              class="block mx-0 my-4 hover:text-primary-light"
              to="/plan-videos"
              >Plan Videos</router-link
            >
          </div>
          <div
            class="flex flex-col justify-start mb-8 text-center sm:text-left sm:ml-4 sm:w-40"
          >
            <div>
              <h3 class="mt-0 font-semibold">COMPANY</h3>
              <router-link
                class="block mx-0 my-4 hover:text-primary-light"
                to="/about"
                >About Us</router-link
              >
              <router-link
                class="block mx-0 my-4 hover:text-primary-light"
                to="/contact"
                >Contact Us</router-link
              >
              <a
                href="https://rockymountainreserve.applytojob.com/apply"
                class="block mx-0 my-4 hover:text-primary-light"
              >
                Careers
              </a>
              <a
                href="https://trust.rmrbenefits.com/"
                class="block mx-0 my-4 hover:text-primary-light"
              >
                Trust Center
              </a>
            </div>
            <div class="mt-6">
              <h3 class="mt-0 font-semibold">LEGAL</h3>
              <router-link
                class="block mx-0 my-4 hover:text-primary-light"
                to="/privacy"
                >Privacy</router-link
              >
              <router-link
                class="block mx-0 my-4 hover:text-primary-light"
                to="/terms-of-use"
                >Terms of Use</router-link
              >
            </div>
          </div>
        </div>
        <div
          class="relative flex flex-col items-center justify-center order-first mb-16 md:order-none md:mb-8 md:w-60"
        >
          <Button
            reg
            large-icons
            slide-text-right="888.722.1223"
            trailing="flaticon-phone"
            href="tel:8887221223"
            class="mb-4 w-72 md:w-52"
          >
            Give Us A Call
          </Button>

          <Button
            reg
            large-icons
            trailing="flaticon-email"
            slide-text-right="info@rmrbenefits.com"
            href="mailto:info@rmrbenefits.com"
            tooltip-click="Email Copied!"
            @click-button="setClipboard('info@rmrbenefits.com')"
            class="mb-4 w-72 md:w-52"
          >
            Send Us An Email
          </Button>

          <!-- <Button
            reg
            large-icons
            trailing="flaticon-question"
            @click-button="openChat()"
            class="mb-4 w-72 md:w-52"
          >
            Chat Live With Us
          </Button> -->
          <!-- <Button
            reg
            large-icons
            trailing="flaticon-question"
            onclick="zE('messenger', 'open')"
            class="mb-4 w-72 md:w-52"
          >
            Chat Live With Us
          </Button> -->
          <div
            class="w-full mx-8 mt-6 text-center flex justify-evenly items-center"
          >
            <a
              href="https://www.facebook.com/RMRBenefits/"
              class="h-12 w-12 hover:bg-primary-mid rounded-full flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-facebook w-8 h-8"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"
                />
              </svg>
            </a>
            <a
              href="https://twitter.com/rmrbenefits"
              class="h-12 w-12 hover:bg-primary-mid rounded-full flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-twitter-x w-6 h-6"
                viewBox="0 0 16 16"
              >
                <path
                  d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"
                />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/rocky-mountain-reserve?trk=tyah&trkInfo=clickedVertical%3Acompany%2CentityType%3AentityHistoryName%2CclickedEntityId%3Acompany_514535%2Cidx%3A0"
              class="h-12 w-12 hover:bg-primary-mid rounded-full flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-linkedin w-6 h-6"
                viewBox="0 0 16 16"
              >
                <path
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"
                />
              </svg>
            </a>
          </div>
          <!-- <div class="w-full mx-0 mt-2 text-center">
            <a href="https://www.facebook.com/RMRBenefits/">
              <div id="facebook" class="inline-block w-10 h-10 mx-3 my-0" />
            </a>
            <a href="https://twitter.com/rmrbenefits">
              <div id="twitter" class="inline-block w-10 h-10 mx-3 my-0" />
            </a>
            <a
              href="https://www.linkedin.com/company/rocky-mountain-reserve?trk=tyah&trkInfo=clickedVertical%3Acompany%2CentityType%3AentityHistoryName%2CclickedEntityId%3Acompany_514535%2Cidx%3A0"
            >
              <div id="linkedin" class="inline-block w-10 h-10 mx-3 my-0" />
            </a>
          </div> -->
        </div>
      </div>
      <p class="w-full text-center copyright">
        Copyright © 2021 Rocky Mountain Reserve
      </p>
    </div>
  </footer>
</template>

<script>
// import ContactHoverButton from "@/components/global/footer/components/ContactHoverButton.vue";
import Button from "@/components/ui/Button.vue";

export default {
  components: {
    // ContactHoverButton,
    Button
  },
  mounted() {
    this.$zendesk.load("a95486a9-0bcf-4bda-99ac-f81566ab1958");
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // handleScroll() {
    //   let footer = document.getElementById("footer");
    //   let button = document.getElementById("contact-hover-button");
    //   let width = window.matchMedia("(min-width: 550px)");

    //   let footerRect = footer.getBoundingClientRect();
    //   let heightOfFooter = window.innerHeight - footerRect.top;

    //   if (heightOfFooter > 200 || !width.matches) {
    //     button.style.opacity = "0";
    //     setTimeout(() => {
    //       if (button.style.opacity === "0") {
    //         button.style.visibility = "hidden";
    //       }
    //     }, 250);
    //   } else {
    //     button.style.visibility = "visible";
    //     button.style.opacity = "1";
    //   }
    // },
    setClipboard(value) {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    }
    // openChat() {
    //   var el = this;
    //   this.$zendesk.show();
    //   this.$zendesk.open();
    //   this.$zendesk.zE("webWidget:on", "close", () => {
    //     el.$zendesk.hide();
    //   });
    // }
  }
};
</script>

<style scoped>
#linkedin {
  background: url("~@/assets/socialMedia/LinkedIn-White.png");
  background-size: contain;
}

#linkedin:hover {
  background: url("~@/assets/socialMedia/LinkedIn-Color.png");
  background-size: contain;
}

#twitter {
  background: url("~@/assets/socialMedia/Twitter-White.png");
  background-size: contain;
}

#twitter:hover {
  background: url("~@/assets/socialMedia/Twitter-Color.png");
  background-size: contain;
}

#facebook {
  background: url("~@/assets/socialMedia/Facebook-White.png");
  background-size: contain;
}

#facebook:hover {
  background: url("~@/assets/socialMedia/Facebook-Color.png");
  background-size: contain;
}
</style>

<!-- uncomment Line 6, 178, 183, handlescroll, and openChat -->
